// @generated by protoc-gen-es v1.10.0 with parameter "target=ts+js"
// @generated from file hivtest/v1/api.proto (package hivtest.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum hivtest.v1.TokenState
 */
export const TokenState = /*@__PURE__*/ proto3.makeEnum(
  "hivtest.v1.TokenState",
  [
    {no: 0, name: "TOKEN_STATE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TOKEN_STATE_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "TOKEN_STATE_EXPIRED", localName: "EXPIRED"},
    {no: 3, name: "TOKEN_STATE_DELETED", localName: "DELETED"},
  ],
);

/**
 * @generated from enum hivtest.v1.DispenseStatus
 */
export const DispenseStatus = /*@__PURE__*/ proto3.makeEnum(
  "hivtest.v1.DispenseStatus",
  [
    {no: 0, name: "DISPENSE_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DISPENSE_STATUS_PENDING_DISPENSING", localName: "PENDING_DISPENSING"},
    {no: 2, name: "DISPENSE_STATUS_DISPENSING", localName: "DISPENSING"},
    {no: 3, name: "DISPENSE_STATUS_DISPENSED", localName: "DISPENSED"},
    {no: 4, name: "DISPENSE_STATUS_FAILED", localName: "FAILED"},
  ],
);

/**
 * @generated from message hivtest.v1.LoginRequest
 */
export const LoginRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.LoginRequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hivtest.v1.LoginResponse
 */
export const LoginResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.LoginResponse",
  () => [
    { no: 1, name: "bearer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hivtest.v1.Dispense
 */
export const Dispense = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.Dispense",
  () => [
    { no: 1, name: "dispense_timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "machine_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hivtest.v1.TokenInfo
 */
export const TokenInfo = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.TokenInfo",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "creation_timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(TokenState) },
    { no: 5, name: "dispenses", kind: "message", T: Dispense, repeated: true },
    { no: 6, name: "remaining_dispense_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "expiry_timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "next_quota_reset_timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message hivtest.v1.GetLoginInfoRequest
 */
export const GetLoginInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.GetLoginInfoRequest",
  [],
);

/**
 * @generated from message hivtest.v1.GetLoginInfoResponse
 */
export const GetLoginInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.GetLoginInfoResponse",
  () => [
    { no: 1, name: "token_info", kind: "message", T: TokenInfo },
  ],
);

/**
 * @generated from message hivtest.v1.ListVendingMachinesRequest
 */
export const ListVendingMachinesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.ListVendingMachinesRequest",
  [],
);

/**
 * @generated from message hivtest.v1.ListVendingMachinesResponse
 */
export const ListVendingMachinesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.ListVendingMachinesResponse",
  () => [
    { no: 1, name: "vending_machines", kind: "message", T: VendingMachineInfo, repeated: true },
  ],
);

/**
 * @generated from message hivtest.v1.VendingMachineInfo
 */
export const VendingMachineInfo = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.VendingMachineInfo",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "photo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_online", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "machine_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "location_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "opening_hours", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "no_voti", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message hivtest.v1.FindVendingMachineRequest
 */
export const FindVendingMachineRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.FindVendingMachineRequest",
  () => [
    { no: 1, name: "machine_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hivtest.v1.FindVendingMachineResponse
 */
export const FindVendingMachineResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.FindVendingMachineResponse",
  () => [
    { no: 1, name: "vending_machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "vending_machine", kind: "message", T: VendingMachineInfo },
  ],
);

/**
 * @generated from message hivtest.v1.DispenseTestKitRequest
 */
export const DispenseTestKitRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.DispenseTestKitRequest",
  () => [
    { no: 1, name: "vending_machine_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hivtest.v1.DispenseTestKitResponse
 */
export const DispenseTestKitResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.DispenseTestKitResponse",
  () => [
    { no: 1, name: "dispense_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hivtest.v1.QueryDispenseStatusRequest
 */
export const QueryDispenseStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.QueryDispenseStatusRequest",
  () => [
    { no: 1, name: "dispense_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message hivtest.v1.QueryDispenseStatusResponse
 */
export const QueryDispenseStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "hivtest.v1.QueryDispenseStatusResponse",
  () => [
    { no: 1, name: "dispense_status", kind: "enum", T: proto3.getEnumType(DispenseStatus) },
  ],
);

