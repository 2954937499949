import React, { useEffect, useState } from 'react';
import LogoHeader from '../../logo_header';
import CustomConfirmBtn from '../customConfirmBtn/custom_confirm_btn';
import { LoginService } from '../../api/v1/api_connect';
import { useClient } from '../../connect_api';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { tokenKey } from '../../constant';
import invalidError from '../../assets/invalidError.png';
import CustomModal from '../customModal/custom_modal';
import { IAuthenticationContext, withAuthentication } from '../../authentication';
import CustomPinInput from './custom_pin_input';
import { Helmet } from 'react-helmet';

interface Props extends IAuthenticationContext {

}

function AccessCodeInputPage(props: Props) {
  const [otp, setOtp] = useState<string>("");
  const client = useClient(LoginService);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { code } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const loginFunction = async (token: string) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await client.login({ token });
      localStorage.removeItem(tokenKey);
      localStorage.setItem(tokenKey, response.bearerToken);
      props.login(response.bearerToken);
      navigate('/', { replace: true });
    } catch (error) {
      if (props.authentication.authenicated) {
        return;
      }
      openModal();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (code !== undefined && code.length === 6) {
      setOtp(code);
      setErrorMessage('');
      loginFunction(code);
      return 
    }

    if (props.authentication.authenicated) {
      navigate('/', { replace: true });
    } else if (code !== undefined) {
      setOtp(code);
      setErrorMessage('');
      loginFunction(code);
    }
    // eslint-disable-next-line
  }, [code, props.authentication.authenicated, navigate]);

  const token = localStorage.getItem(tokenKey);
  if (token) {
    navigate('/', { replace: true });
    return null;
  }

  const onChallengeCodeChange = (value: string) => {
    setErrorMessage('');
    setOtp(value);
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (otp.length !== 6) {
      setErrorMessage('Invalid Input');
      return;
    }
    loginFunction(otp);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Helmet>
        <title>AccessCode Page</title>
      </Helmet>
      {modalOpen && <CustomModal title={'Oopsie, Invalid Code'} imageUrl={invalidError} text={'The code you entered is invalid or expired. Please check the code and try again.'} closeModal={closeModal} cancelButtonTitle='Close' />}
      <div className="px-3">
        <div className="container accessCodeMT">
          <LogoHeader text="Please enter the 6-character access code sent to you via email or mobile" />
          <CustomPinInput length={6} initialValue={code} onChange={onChallengeCodeChange} />
          <div style={{
            width: "100%",
            height: "24px",
          }}>
            <label htmlFor="accessCodeInput" className='accessErrorLabel'>{errorMessage}</label>
          </div>
          <CustomConfirmBtn confirmText='Confirm' confirmAction={handleLogin} />
          <span className='accessSpan'>Didn't receive the code? <Link to="https://mytest.health.nsw.gov.au/" className='accessa'>Registration</Link></span>
        </div>
      </div>
    </div>
  );
}
export default withAuthentication(AccessCodeInputPage);
