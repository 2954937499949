import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DashboardPage from './pages/dashboard/dashboard_page';
import './App.css';
import { tokenKey } from './constant';
import MachineDetailPage from './pages/machine_detail/machine_detail_page';

import AccessCodeInputPage from "./pages/accessCode/access_code_input_page";
import { AuthenticationProtected } from "./authentication_protected";
import VendingMachinePage from "./pages/vendingMachinMap/vending_machine_page";
import { AuthenticationContext } from './authentication';



function App() {
  const navigator = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [authenticated, setAuthenticated] = useState({
    authenicated: false,
    bearer_token: '',
  });

  const login = (token: string) => {
    if (token != null){
      localStorage.setItem(tokenKey, token);
      setAuthenticated({ authenicated: true, bearer_token: token });
    }else{
      localStorage.removeItem(tokenKey);
      setAuthenticated({ authenicated: false, bearer_token: '' });
    }
  };

  const logout = () => {
    localStorage.removeItem(tokenKey);
    setAuthenticated({ authenicated: false, bearer_token: '' });
    navigator('/access-code');
  }

  const init = () => {
    const token = localStorage.getItem(tokenKey);
    if (token) {
      login(token);
    } else {
      setAuthenticated({
        authenicated: false,
        bearer_token: '',
      });
    }
    setInitialized(true); 
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, [])


  return initialized ? (
    <div className="d-flex flex-column sticky-footer-wrapper min-vh-100 backgroundPrimary">
      <AuthenticationContext.Provider
        value={{
          authentication: authenticated,
          login: login,
          logout: logout,
        }}
      >
        <Routes>
          <Route path="access-code" element={<AccessCodeInputPage />} />
          <Route path="access-code/:code" element={<AccessCodeInputPage />} /> 
          <Route path="/" element={<AuthenticationProtected><DashboardPage/></AuthenticationProtected>} />
          <Route path="find-machine" element={<AuthenticationProtected><VendingMachinePage /></AuthenticationProtected>} />
          <Route path="machine-detail/:id" element={<AuthenticationProtected><MachineDetailPage/></AuthenticationProtected>}/>
          <Route path="*" element={<NavigateToDashboard />} />
        </Routes>
      </AuthenticationContext.Provider>
    </div>
  ): null;
}

function NavigateToDashboard() {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate('/', { replace: true });
  }, [navigate]);
  return null;
}


export default App;
