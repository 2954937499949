import React from 'react';
import logo from './assets/logo.png';

interface Props {
    text: string;
    title?: string;
    brText?: string;
}

export default function LogoHeader(props: Props) {
    return (
        <div className="backgroundPrimary text-center pb-3">
            <div className="container">
                <div className="py-4">
                    <img className="rounded-top rounded-bottom mw-100 mx-auto headImageSizeStyle" src={logo} alt="" />
                </div>
                {props.title && <p className="text-center textPrimary headTitleSizeStyle">{props.title}</p>}
                <span className="text-center textPrimary headTextSizeStyle">{props.text}<br/>{props.brText}</span>
            </div>
        </div>
    );
}
