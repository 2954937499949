import React, { useState, useEffect, useCallback } from 'react'
import defaultMachinePhotoUrl from '../../assets/machine.png'
import LogoHeader from '../../logo_header'
import CustomConfirmBtn from '../customConfirmBtn/custom_confirm_btn'
import CustomModal from '../customModal/custom_modal';
import DispensingFailed from '../../assets/dispensingFailed.png'
import DispensingFinished from '../../assets/dispendingFinished.png'
import { HivTestService } from '../../api/v1/api_connect';
import { useClient } from '../../connect_api';
import { useNavigate, useParams } from 'react-router-dom';
import { DispenseStatus, VendingMachineInfo } from '../../api/v1/api_pb';
import { Helmet } from 'react-helmet';
import TextBtn from '../textButton/text_button';

export default function MachineDetailPage() {
    const [failedModalOpen, setFailedModalOpen] = useState(false);
    const [finishedModalOpen, setFinishedModalOpen] = useState(false);
    const [codeErrorModalOpen, setCodeErrorModalOpen] = useState(false);
    const [isDispensing, setIsDispensing] = useState(false);
    const [machine, setMachine] = useState<VendingMachineInfo>();
    const [vendingMachineId, setVendingMachineId] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const client = useClient(HivTestService);
    const { id: code } = useParams();
    const navigate = useNavigate();
    let startTime: number | null = null;
    // const timeoutInMs = 120000; // two minutes
    const timeoutInMs = 60000; // one minutes

    const findVendingMachine = useCallback(async () => {
        try {
            const response = await client.findVendingMachine({ machineCode: code });
            setMachine(response.vendingMachine);
            setVendingMachineId(response.vendingMachineId);
        } catch (error) {
            openCodeErrorModal();
        }
    }, [client, code]);

    useEffect(() => {
        const getLoginInfoFunction = async () => {
            await client.getLoginInfo({}).then((response) => {
                setIsButtonDisabled((response.tokenInfo?.remainingDispenseCount ?? 0) > 0);
            }).catch((error: any) => {
                let errorMessage: string = 'An unknown error occurred';
                const errorString: string = error.toString();
                const match = errorString.match(/\]\s*(.+)/);
                if (match && match[1]) {
                    errorMessage = match[1].trim();
                }
                setErrorMessage(errorMessage);
                openFailedModal();
                return;
            });
        };
        getLoginInfoFunction();
        findVendingMachine();
    }, [client, code, navigate, findVendingMachine]);


    const [isPendingMachineInput, setIsPendingMachineInput] = useState(false);

    const dispenseTestKit = async () => {
        try {
            const response = await client.dispenseTestKit({ vendingMachineId: vendingMachineId });
            const pollingTimer = setInterval(async () => {
                if (startTime === null) {
                    startTime = Date.now();
                }

                // checkout time out
                if (Date.now() - startTime >= timeoutInMs) {
                    clearInterval(pollingTimer);
                    setErrorMessage('Checkout Timed Out');
                    setIsDispensing(false);
                    openFailedModal();
                    return;
                }
                try {
                    const stateResponse = await client.queryDispenseStatus({ dispenseId: response.dispenseId });
                    if (stateResponse.dispenseStatus === DispenseStatus.FAILED) {
                        clearInterval(pollingTimer);
                        setIsDispensing(false);
                        openFailedModal();
                    }

                    // customer has made selection on the vending machine.
                    if (stateResponse.dispenseStatus === DispenseStatus.DISPENSING) {
                        console.log('set is pending false');
                        setIsPendingMachineInput(false);
                    }

                    if (stateResponse.dispenseStatus === DispenseStatus.DISPENSED) {
                        clearInterval(pollingTimer);
                        setIsDispensing(false);
                        openFinishedModal();
                    }
                } catch (error) {
                    clearInterval(pollingTimer);
                    setErrorMessage('An unknown error occurred');
                    setIsDispensing(false);
                    openFailedModal();
                }
            }, 1000);
        } catch (error: any) {
            let errorMessage: string = 'An unknown error occurred';
            const errorString: string = error.toString();
            const match = errorString.match(/\]\s*(.+)/);
            if (match && match[1]) {
                errorMessage = match[1].trim();
            }
            setErrorMessage(errorMessage);
            setIsDispensing(false);
            openFailedModal();
        }
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsChecked(e.target.checked);
    };

    const handleConfirmAction = () => {
        if (!isChecked) {
            alert('Please check the box before confirming.');
            return;
        }

        if (code !== undefined) {
            setIsDispensing(true);

            // if it's dumb machine
            if (machine?.noVoti === true) {
                console.log('set is pending true');
                setIsPendingMachineInput(true);
            }

            dispenseTestKit();
        }
    };


    const openFailedModal = () => setFailedModalOpen(true);
    const openFinishedModal = () => setFinishedModalOpen(true);
    const openCodeErrorModal = () => setCodeErrorModalOpen(true);

    return (
        <div className="container justify-content-center align-items-center">
            <Helmet>
                <title>MachineDetail Page</title>
            </Helmet>
            {codeErrorModalOpen && <CustomModal title={'Oopsie, Machine Code Error.'} imageUrl={DispensingFailed} text={`The machine code you entered is incorrect`} isShowContactUs={false} cancelButtonTitle='Close' closeModal={() => {
                setCodeErrorModalOpen(false);
                navigate('/find-machine', {
                    state: true
                });
            }} />}
            {failedModalOpen && <CustomModal title={'Oopsie, dispensing failed.'} imageUrl={DispensingFailed} text={errorMessage !== '' ? errorMessage : `We couldn't dispense the product at the moment. Please try again later or `} isShowContactUs={errorMessage !== '' ? false : true} cancelButtonTitle='Close' closeModal={() => {
                setFailedModalOpen(false);
                navigate('/');
            }} />}
            {finishedModalOpen && <CustomModal title={'Dispensing Finished'} imageUrl={DispensingFinished} isConinue={true} text={`Thank you for using our HIV testing service and successfully completing the dispensing process.`} cancelButtonTitle='Send Feedback' showFeedBackMessage={false} closeModal={() => {
                setFinishedModalOpen(false);
            }} />}
            <div className="py-1 px-3">
                <LogoHeader title={`Thank you for selecting ${machine?.name} to collect your free HIV self-test kits from. You can collect your free kits ${machine?.openingHours}`} text={`When you are standing in front of the machine, please click the button below. Please don't press the button until you are standing in front of the machine. `} />
                <img className='machineDetailImg' src={machine?.photoUrl ? machine?.photoUrl : defaultMachinePhotoUrl} alt='' />
                <div className="checkbox-container">
                    <input
                        type="checkbox"
                        id="confirmCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{ transform: 'scale(1.5)', marginRight: '10px' }}
                    />
                    <label htmlFor="confirmCheckbox" style={{ fontSize: '14px', marginTop: '20px' }}>I am standing in front of the machine and ready to collect my test kit</label>
                </div>


                <div className={"py-4"}>
                    {isDispensing ? (
                            isPendingMachineInput ? (
                                    <div className={`background-box mb-4 ${isDispensing ? 'dispensing' : ''}`}>
                                        <div className="progress">Please select 11 on the vending machine</div>
                                    </div>
                                ) :
                                (
                                    <div className={`background-box mb-4 ${isDispensing ? 'dispensing' : ''}`}>
                                        <div className="progress">Dispensing..</div>
                                    </div>
                                )

                    ) : (
                        <CustomConfirmBtn
                            disabled={!isButtonDisabled || !isChecked}
                            confirmText={!isButtonDisabled ? 'Monthly quota exhausted' : `I am ready to collect`}
                            confirmAction={!isButtonDisabled ? () => { } : handleConfirmAction}
                        />
                    )}
                    <TextBtn textButtonTitle='Go Back' textBtnFunction={() => {
                        navigate('/');
                    }} />
                </div>
            </div>
        </div>
    )
}
