import {ServiceType} from "@bufbuild/protobuf";
import {createConnectTransport,} from "@bufbuild/connect-web";

import {createPromiseClient, Interceptor, PromiseClient} from "@bufbuild/connect";

import {useMemo} from "react";

import { tokenKey } from "./constant";

const authInjector: Interceptor = (next) => async (req) => {
    const token = localStorage.getItem(tokenKey);
    req.header.set("X-MyTest-Client", `mytest-client`);
    if (token) {
        req.header.set("Authorization", `Bearer ${token}`);
    }

    return await next(req);
};


const transport = createConnectTransport({
    baseUrl: "/",
    interceptors: [authInjector]
});

export function useClient<T extends ServiceType>(service: T): PromiseClient<T> {
    // We memoize the client, so that we only create one instance per service.
    return useMemo(() => {
        const client = createPromiseClient(service, transport);
        return client;
    }, [service]);
}

