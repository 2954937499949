import React from 'react'

interface Props {
    textBtnFunction: ()=>void;
    textButtonTitle: string;
}


export default function TextBtn(prop: Props) {
  return (
    <div>
      <button
            type='button'
            className='textBtn'
            onClick={prop.textBtnFunction}
          >
            {prop.textButtonTitle}
          </button>
    </div>
  )
}
