export default function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp * 1000); 
  const options = {
    timeZone: 'Australia/Sydney',
    year: 'numeric' as const,
    month: 'long' as const,
    day: 'numeric' as const
  };

  const formattedDate = date.toLocaleDateString('en-AU', options);

  return formattedDate;
}
