import React, { useRef } from 'react';
import TextBtn from '../textButton/text_button';
import CustomConfirmBtn from '../customConfirmBtn/custom_confirm_btn';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  imageUrl: string;
  text: string;
  closeModal: () => void;
  isShowContactUs?: boolean;
  cancelButtonTitle: string;
  showFeedBackMessage?: boolean;
  seedFeedBack?:() => void;
  notSeedFeedBack?:() => void;
  isConinue?: boolean;
}

const CustomModal: React.FC<Props> = (prop: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      prop.closeModal();
    }
  };

  return (
    <div
      className='customModal'
      onClick={handleBackgroundClick}
    >
      <div
        ref={modalRef}
        className='customModalDiv'
      >
        <p
         className='customModalP'
        >
          {prop.title}
        </p>
        <img src={prop.imageUrl} alt='' className='customModalImg' />
        <span
          className='customModalSpan'
        >
          {prop.text}
          {prop.isShowContactUs && (<Link to="/" className='customContactUS'>Contact Us</Link>)}
        </span>
        {prop.showFeedBackMessage && (<span className='customFeedBack'>Would you like to provide feedback?</span>)}
        {prop.showFeedBackMessage && (<CustomConfirmBtn confirmText='No,Thanks' confirmAction={prop.closeModal}/>)}
        {prop.isConinue ? 
        <CustomConfirmBtn confirmText='Continue' confirmAction={()=>{
          navigate('/');
        }} /> 
        : <TextBtn textBtnFunction={prop.closeModal} textButtonTitle={prop.cancelButtonTitle} />}
      </div>
    </div>
  );
};

export default CustomModal;