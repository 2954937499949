import React from "react";
import { IAuthenticationContext,withAuthentication } from "./authentication";
import { Navigate} from 'react-router-dom';

interface Props extends IAuthenticationContext{
    children: any
}

function _AuthenticationProtected(props: Props) {

    if (props.authentication.authenicated === null) {
        return (<div/>);
    }

    if (!props.authentication.authenicated){
        return (<Navigate to="/access-code" />);
    }

    if (props.authentication.authenicated){
        return props.children;
    }
}

const AuthenticationProtected = withAuthentication(_AuthenticationProtected);
export { AuthenticationProtected };