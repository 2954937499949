import React, { useRef, useState } from 'react';
import { HivTestService } from '../../api/v1/api_connect';
import { useClient } from '../../connect_api';
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';


interface Props {
    closeModal: () => void;
    title: string;
}

export default function InPutModal(prop: Props) {
    const modalRef = useRef<HTMLDivElement>(null);
    const [otp, setOtp] = useState<string>("");
    const client = useClient(HivTestService);
    const [errorMessages, setErrorMessages] = useState<string>('');
    const navigate = useNavigate();

    const findVendingMachine = async (code:string) => {
        
        try {
            const response = await client.findVendingMachine({machineCode: code});
            prop.closeModal();
            navigate('/machine-detail/'+code,{
                state: {vendingMachine: response.vendingMachine}
            });
          } catch (error) {
            setErrorMessages('Invalid Code');
          } 
    };

    const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            prop.closeModal();
        }
    };

    const onChallengeCodeChange = (value: string, index: number) => {
        setOtp(value)
        setErrorMessages('');
        if (value.length === 4) {
            findVendingMachine(value);
        }
    }

    return (
        <div className='inputModalAllStyle'
            onClick={handleBackgroundClick}
        >
            <div
                ref={modalRef}
                className='inputModalStyle'>
                <p className='inputModalPStyle'>
                    {prop.title}
                </p>
                <PinInput
                    focus = {true}
                    length={4}
                    initialValue={otp}
                    secret={false}
                    onChange={onChallengeCodeChange}
                    type="numeric"
                    inputMode="number"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0 40px",
                    }}
                    inputStyle={{
                        background: "transparent",
                        border: "none",
                        borderBottom: "2px solid #DDDDDD",
                        boxSizing: "border-box",
                        color: "#1a112a",
                        width: "40px",
                        margin: "0 5px",
                        fontFamily: "Inter",
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "29px",
                        letterSpacing: "0.2px",
                        textAlign: "center",
                    }}
                    inputFocusStyle={{
                        background: "transparent",
                        boxSizing: "border-box",
                        borderBottom: "2px solid #6838d3",
                        width: "40px",
                        margin: "0 5px",
                    }}
                    onComplete={(value, index) => { }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                <div style={{
            width: "100%",
            height: "24px",
          }}>
            <label htmlFor="accessCodeInput" className='accessErrorLabel' style={{
                margin: "10px 40px",
            }}>{errorMessages}</label>
          </div>
            </div>
        </div>
    );
}
