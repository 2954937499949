import React, { useEffect, useState } from 'react'
import LogoHeader from '../../logo_header'
import dispnese from '../../assets/dispenseRemaining.png'
import reset from '../../assets/nextResetTiime.png'
import lastDispense from '../../assets/lastDispense.png'
import DashboardItem from './dashboard_item'
import { useClient } from '../../connect_api'
import { HivTestService } from '../../api/v1/api_connect'
import formatTimestamp from '../tool/formatTime'
import { useNavigate } from 'react-router-dom'
import { tokenKey } from '../../constant'
import InputModal from '../inputModal/inputModal'
import { TokenInfo } from '../../api/v1/api_pb'
import { IAuthenticationContext, withAuthentication } from '../../authentication'
import { Helmet } from 'react-helmet'

interface Props extends IAuthenticationContext {

}
function DashboardPage(props: Props) {
    const client = useClient(HivTestService);
    const [tokenInfo, setTokenInfo] = useState<TokenInfo>();
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const getLoginInfoFunction = async () => {
        await client.getLoginInfo({}).then((response) => {
            setTokenInfo(response.tokenInfo);
        }).catch((error) => {
            localStorage.removeItem(tokenKey);
            props.logout();
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            await getLoginInfoFunction();
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <div className={"px-3"}>
            <Helmet>
                <title>Dashboard Page</title>
            </Helmet>
            {modalOpen && <InputModal title='Please input the code on vending machine' closeModal={() => setModalOpen(false)} />}
            <div className={"container accessCodeMT"}>
                <LogoHeader text="Welcome to your MyTest dashboard." brText='You can keep track of how many free HIV self-tests you can get each month, here.' />
                <DashboardItem src={dispnese} title='Number of kits you can get each month:' content={tokenInfo?.remainingDispenseCount?.toString() ?? '-'} />
                <DashboardItem src={reset} title='Reset date:' content={formatTimestamp(Number(tokenInfo?.nextQuotaResetTimestamp ?? 0))} />
                <DashboardItem
                    src={lastDispense}
                    title='Date you last collected a kit:'
                    content={
                        tokenInfo?.dispenses?.[0]?.dispenseTimestamp
                            ? formatTimestamp(Number(tokenInfo.dispenses[0].dispenseTimestamp))
                            : '-'
                    }
                />
                <div className={"mt-6"}>
                    <button className="textButton btn dashBoardFindMachine"
                        onClick={() => {
                            navigate('/find-machine');
                        }}>
                        Find a MyTest vending machine
                    </button>
                </div>
            </div>
        </div>
    )
}


export default withAuthentication(DashboardPage);