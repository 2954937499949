// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts+js"
// @generated from file hivtest/v1/api.proto (package hivtest.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DispenseTestKitRequest, DispenseTestKitResponse, FindVendingMachineRequest, FindVendingMachineResponse, GetLoginInfoRequest, GetLoginInfoResponse, ListVendingMachinesRequest, ListVendingMachinesResponse, LoginRequest, LoginResponse, QueryDispenseStatusRequest, QueryDispenseStatusResponse } from "./api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * This service does not require authentication
 *
 * @generated from service hivtest.v1.VendingMachineService
 */
export const VendingMachineService = {
  typeName: "hivtest.v1.VendingMachineService",
  methods: {
    /**
     * List all vending machines
     *
     * @generated from rpc hivtest.v1.VendingMachineService.ListVendingMachines
     */
    listVendingMachines: {
      name: "ListVendingMachines",
      I: ListVendingMachinesRequest,
      O: ListVendingMachinesResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * This service does not require authentication
 *
 * @generated from service hivtest.v1.LoginService
 */
export const LoginService = {
  typeName: "hivtest.v1.LoginService",
  methods: {
    /**
     * Login with 6-char unique token
     *
     * @generated from rpc hivtest.v1.LoginService.Login
     */
    login: {
      name: "Login",
      I: LoginRequest,
      O: LoginResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service hivtest.v1.HivTestService
 */
export const HivTestService = {
  typeName: "hivtest.v1.HivTestService",
  methods: {
    /**
     * Get current login info
     *
     * @generated from rpc hivtest.v1.HivTestService.GetLoginInfo
     */
    getLoginInfo: {
      name: "GetLoginInfo",
      I: GetLoginInfoRequest,
      O: GetLoginInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Find vending machine by machine code
     *
     * @generated from rpc hivtest.v1.HivTestService.FindVendingMachine
     */
    findVendingMachine: {
      name: "FindVendingMachine",
      I: FindVendingMachineRequest,
      O: FindVendingMachineResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Dispense test kit.
     * Flow:
     * 1. Call DispenseTestKit method, get dispense_id
     * 2. Poll QueryDispenseStatus repeatedly with dispense_id returned in step 1, until the state changes to DISPENSE_STATUS_DISPENSED or DISPENSE_STATUS_FAILED
     *
     * @generated from rpc hivtest.v1.HivTestService.DispenseTestKit
     */
    dispenseTestKit: {
      name: "DispenseTestKit",
      I: DispenseTestKitRequest,
      O: DispenseTestKitResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Query dispense status by dispense id
     *
     * @generated from rpc hivtest.v1.HivTestService.QueryDispenseStatus
     */
    queryDispenseStatus: {
      name: "QueryDispenseStatus",
      I: QueryDispenseStatusRequest,
      O: QueryDispenseStatusResponse,
      kind: MethodKind.Unary,
    },
  }
};

