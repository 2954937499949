import React from 'react'

interface Props {
    src: string;
    title: string;
    content: string;
}

export default function DashboardItem(prop: Props) {
  return (
     <div className={"row mb-4 dashBoardRow"}>
        <div className={"col-3"}>
            <img className={"dashBoardRowIcon"} src={prop.src} alt="" />
        </div>
        <div className={"col-8 offset-col-1"}>
            <span className={"card-title dashBoardItemSpan"}>{prop.title}</span>
            <h5 className={"card-text dashBoardItemText"}>{prop.content}</h5>
        </div>
    </div>
  )
}
