import React from "react";

export interface Authentication {
    authenicated: boolean | null; // null means authentication is loading
    bearer_token?: string;
}

export interface IAuthenticationContext{
    authentication: Authentication;

    login(token: string):void;

    logout():void;
} 

const AuthenticationContext = React.createContext<IAuthenticationContext>(
    {
        authentication: {
            authenicated: null,
        },
        logout: () => {},
        login: (t) : Promise<any> => {
            return new Promise((resolve, reject) => {
            });
        },
    }
)

function withAuthentication(C: any) {
    return function AuthenticationComponent(props: any) {
        return (
            <AuthenticationContext.Consumer>
                {({authentication, login, logout}) => <C {...props} authentication={authentication} login={login} logout={logout} />}
            </AuthenticationContext.Consumer>
        );
    }
}

export { AuthenticationContext, withAuthentication };