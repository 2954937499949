import React from 'react'

interface Props {
    confirmText: string;
    confirmAction: (e:any) => void;
    disabled?: boolean;
}

export default function CustomConfirmBtn(prop: Props) {
    return (
        <div className="row mb-4 mt-3">
            <button
                disabled={prop.disabled ? prop.disabled : false}
                className='btn confirmBtn'
                onClick={prop.confirmAction}
            >
                {prop.confirmText}
            </button>
        </div>
    )
}
