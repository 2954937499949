import React, { useState, useEffect, useRef } from 'react';
import mapStyle from './mapStyle.json';
import { VendingMachineInfo } from '../../api/v1/api_pb';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';

interface Props {
  vendingMachines: VendingMachineInfo[]
  selectedVendingMachine: VendingMachineInfo | null
  onSelectVendingMachine: Function;
}

const defaultCenter = {
  lat: -33.87473252343564,
  lng: 151.203712449073
};


const API_KEY = "AIzaSyAiK6esuGcx32taWwFdMdQBGlNF0eNQkhs";

const defaultZoom = 12;


export default function VendingMachineMap(props: Props) {
  const mapRef = useRef<HTMLDivElement>(null);
  const [center, setCenter] = useState(defaultCenter);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState(defaultZoom);

  const { selectedVendingMachine, vendingMachines, onSelectVendingMachine } = props;

  useEffect(() => {
    const calculateCenter = () => {
      let validVendingMachines = vendingMachines.filter(vm => 
        vm.latitude !== undefined && vm.longitude !== undefined
      );
    
      if (validVendingMachines.length > 0) {
        let sumLat = 0;
        let sumLng = 0;
    
        validVendingMachines.forEach(vm => {
          sumLat += vm.latitude;
          sumLng += vm.longitude;
        });
    
        setCenter({
          lat: sumLat / validVendingMachines.length,
          lng: sumLng / validVendingMachines.length
        });
      } else {
        setCenter(defaultCenter);
      }
    };

    if (selectedVendingMachine !== null) {
      setCenter({
        lat: selectedVendingMachine.latitude ?? defaultCenter.lat,
        lng: selectedVendingMachine.longitude ?? defaultCenter.lng
      });
      setZoom(15);
    } else {
      calculateCenter();
    }
  }, [selectedVendingMachine, vendingMachines]);

  
  useEffect(() => {
    const loaderOptions: LoaderOptions = {
      apiKey: API_KEY,
      version: 'weekly',
      nonce: 'base64value',
      libraries: ['places']
    };

    const loader = new Loader(loaderOptions);

    loader.load().then(() => {
      if (mapRef.current) {
        const mapInstance = new google.maps.Map(mapRef.current, {
          center: center,
          zoom: zoom,
          styles: mapStyle
        });
        setMap(mapInstance);
      }
    }).catch(error => {
      console.error('Error loading Google Maps:', error);
    });
  }, [center, zoom]);

  useEffect(() => {
    if (map) {
      vendingMachines.forEach((vendingMachine, index) => {
        const marker = new google.maps.Marker({
          position: { lat: vendingMachine.latitude ?? 0, lng: vendingMachine.longitude ?? 0 },
          map: map,
          title: vendingMachine.address ?? '-',
          label: {
            text: `${index + 1}`,
            color: '#ffffff',
            fontSize: '18px',
            fontWeight: 'bold',
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: selectedVendingMachine === vendingMachine ? '#6838d3' : '#000000',
            fillOpacity: 1,
            strokeColor: '#ffffff',
            strokeWeight: 2,
            scale: 20,
          },
        });
        marker.addListener('click', () => {
          onSelectVendingMachine(vendingMachine);
        });
      });
    }
  }, [map, vendingMachines, selectedVendingMachine, onSelectVendingMachine]);

  return <div ref={mapRef} style={{ width: '100%', height: `calc(100vh - 330px)` }} />;
}