import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';

interface Props {
  length: number;
  initialValue?: string;
  onChange: (value: string) => void;
}

const CustomPinInput = (props: Props) => {
  const [value, setValue] = useState<string>(props.initialValue || '');
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (!props.initialValue && inputRefs.current.length > 0) {
      inputRefs.current[0]?.focus();
    }
  }, [props.initialValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = [...value];
    newValue[index] = e.target.value;
    setValue(newValue.join(''));
    props.onChange(newValue.join(''));

    const nextIndex = index + 1;
    if (nextIndex < props.length && inputRefs.current[nextIndex]) {
      inputRefs.current[nextIndex]!.focus();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && !e.currentTarget.value) {
      const newValue = [...value];
      newValue[index - 1] = '';
      setValue(newValue.join(''));
      props.onChange(newValue.join(''));

      const prevIndex = index - 1;
      if (inputRefs.current[prevIndex]) {
        inputRefs.current[prevIndex]!.focus();
      }
    }
  };

  const isAlphaNumeric = (char: string): boolean => /^[a-zA-Z0-9]+$/.test(char);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const char = e.key;
    if (!isAlphaNumeric(char) && char !== 'Backspace' && char !== 'Delete') {
      e.preventDefault();
    }
  };

  const inputBoxes = Array(props.length)
    .fill('')
    .map((_, index) => {
      const hasValue = value[index] !== undefined && value[index] !== '';

      const inputStyle: React.CSSProperties = {
        background: 'transparent',
        border: 'none',
        borderBottom: hasValue ? '2px solid #6838d3' : '2px solid #DDDDDD',
        borderTop: 'none',
        boxSizing: 'border-box',
        color: '#1a112a',
        width: '2.75em', 
        height: '2.75em',
        marginLeft:'0.25em',
        marginRight:'0.25em',
        fontFamily: 'Inter',
        fontSize: '1.5rem', 
        fontWeight: '500',
        lineHeight: '1.8125rem', 
        letterSpacing: '0.2px',
        textAlign: 'center',
        textTransform: 'none',
        outline: 'none',
        paddingBottom: '0.5em',
      };

      return (
        <input
          key={index}
          maxLength={1}
          value={value[index] || ''}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onKeyPress={(e) => handleKeyPress(e, index)}
          style={inputStyle}
          ref={(ref) => (inputRefs.current[index] = ref)}
        />
      );
    });

  return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>{inputBoxes}</div>;
};

export default CustomPinInput;
